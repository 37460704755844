import { useState } from 'react';
import axios from 'axios';

const projectID = 'cf01a4c5-909d-4d94-8a26-298fc2123a82';
const demoInfo = 'Please try this application using  Username: demouser and Password: demo246';

const Modal = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(demoInfo);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const authObject = { 'Project-ID': projectID, 'User-Name': username.toLowerCase(), 'User-Secret': password };

    try {
      await axios.get('https://api.chatengine.io/chats', { headers: authObject });
      localStorage.setItem('username', username);
      localStorage.setItem('password', password);
      window.location.reload();
    } catch (err) {
      setError(`Invalid Login Attempt. ${demoInfo}`);
      setUsername('');
      setPassword('');
    }
  };

  return (
    <div className="wrapper">
      <div className="form">
        <h1 className="title">FibeChat </h1>
        <div className="subTitle">Chat, Share Photos with Individual or Group</div>
        <form onSubmit={handleSubmit}>
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value.toLowerCase())} className="input" placeholder="Username" required />
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="input" placeholder="Password" required />
          <div align="center">
            <button type="submit" className="button">
              <span>Login</span>
            </button>
            <br />
            <h4 className="error">{error}</h4>
          </div>
        </form>
        <div className="footer">Developed with passion by Karthik (aswamykarthik@gmail.com)</div>
      </div>
    </div>

  );
};

export default Modal;
